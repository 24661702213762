<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import EmailSearchForm from "@/components/EmailSearchForm/EmailSearchForm";

export default {
  components: { Layout, PageHeader, EmailSearchForm },

  data() {
    return {
      title: "Actividad de email",
      items: [
        {
          text: "email",
          active: true,
        },
      ],
      defaultField: "correo receptor", // Valor por defecto del select
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <EmailSearchForm :defaultField="defaultField" />
  </Layout>
</template>