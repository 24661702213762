/* import { authHeader } from '../authservice/auth-header'; */
import axios from "../axios";
/* import VueJwtDecode from 'vue-jwt-decode' */

export const mailService = {
  getLog,
  getStats,
  getMessages,
};

function getLog(params) {
  //se manda a traer de este lado la paginació
  return axios.get("v2/consultLog", { params }).then((response) => {
    if (response.data.status == "success") {
      if (response.data.data) {
        console.log("from service" + response.data);
        return response.data;
      }
    } else {
      //return message errror
    }
  });
}

function getStats(params) {
  return axios.get("v2/statsMail", { params }).then((response) => {
    if (response.data.status == "success") {
      if (response.data.data) {
        console.log("from service" + response.data);
        return response.data;
      }
    } else {
      //return message errror
    }
  });
}

function getMessages(params) {
  return axios.get("v2/consultMessages", { params }).then((response) => {
    if (response.data.status == "success") {
      if (response.data.data) {
        console.log("from service" + response.data);
        return response.data;
      }
    } else {
      //return message errror
    }
  });
}
