<template>
  <Loader :show="loader"></Loader>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form
              id="search-mail"
              class="needs-validation"
              @submit.prevent="consultLog"
            >
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                  <div class="mb-3">
                    <label for="field-select">Campo de búsqueda</label>
                    <select
                      id="field-select"
                      v-model="selectedField"
                      class="form-select"
                    >
                      <option value="" disabled selected hidden>
                        Selecciona un campo
                      </option>
                      <option value="correo_receptor">Correo receptor</option>
                      <option value="rfc_emisor">Rfc Emisor</option>
                      <option value="rfc_receptor">Rfc Receptor</option>
                      <option value="uuid">Uuid</option>
                    </select>
                  </div>
                </div>
                <div
                  v-if="selectedField == 'correo_receptor'"
                  class="col-sm-12 col-md-6 col-lg-3"
                >
                  <div class="mb-3">
                    <label for="input_correo_receptor">{{
                      selectedFieldFormatted
                    }}</label>
                    <input
                      id="input_correo_receptor"
                      placeholder="valor"
                      v-model="correoReceptorValue"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && v$.correoReceptorValue.$error,
                      }"
                    />
                    <div
                      v-if="submitted && v$.correoReceptorValue.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.correoReceptorValue.required.$message">{{
                        v$.correoReceptorValue.required.$message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="selectedField == 'rfc_emisor'"
                  class="col-sm-12 col-md-6 col-lg-3"
                >
                  <div class="mb-3">
                    <label for="input_rfc_emisor">{{
                      selectedFieldFormatted
                    }}</label>
                    <input
                      id="input_rfc_emisor"
                      placeholder="valor"
                      v-model="rfcEmisorValue"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && v$.rfcEmisorValue.$error,
                      }"
                    />
                    <div
                      v-if="submitted && v$.rfcEmisorValue.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.rfcEmisorValue.required.$message">{{
                        v$.rfcEmisorValue.required.$message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="selectedField == 'rfc_receptor'"
                  class="col-sm-12 col-md-6 col-lg-3"
                >
                  <div class="mb-3">
                    <label for="input_rfc_receptor">{{
                      selectedFieldFormatted
                    }}</label>
                    <input
                      id="input_rfc_receptor"
                      placeholder="valor"
                      v-model="rfcReceptorValue"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && v$.rfcReceptorValue.$error,
                      }"
                    />
                    <div
                      v-if="submitted && v$.rfcReceptorValue.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.rfcReceptorValue.required.$message">{{
                        v$.rfcReceptorValue.required.$message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="selectedField == 'uuid'"
                  class="col-sm-12 col-md-6 col-lg-3"
                >
                  <div class="mb-3">
                    <label for="input_uuid">{{ selectedFieldFormatted }}</label>
                    <input
                      id="input_uuid"
                      placeholder="________-____-____-____-____________"
                      v-model="uuidValue"
                      type="text"
                      class="form-control input-mask"
                      v-maska="'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'"
                      :class="{
                        'is-invalid': submitted && v$.uuidValue.$error,
                      }"
                    />
                    <div
                      v-if="submitted && v$.uuidValue.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.uuidValue.required.$message">{{
                        v$.uuidValue.required.$message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                  <label for="initial_date-input" class="form-label"
                    >Fecha Inicial</label
                  >
                  <input
                    id="initial_date-input"
                    type="date"
                    class="form-control"
                    v-model="initialDate"
                  />
                </div>

                <div class="col-sm-12 col-md-6 col-lg-3">
                  <label for="end_date-input" class="form-label"
                    >Fecha Final</label
                  >
                  <input
                    id="end_date-input"
                    type="date"
                    class="form-control"
                    v-model="endDate"
                  />
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Buscar</button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
  </Layout>

  <Layout>
    <TableMailComponent
      :logData="logData"
      :headers="headers"
      :pagination="pagination"
      :callback-search="consultLog"
      @on-pagination="pagination = $event"
    />
    <!-- Se envía el objeto params al componente TableMailComponent -->
  </Layout>
</template>

<script>
const headers = [
  {
    index: "correo_receptor",
    label: "Correo receptor",
    filter: false,
    visible: true,
  },
  { index: "rfc_emisor", label: "Rfc emisor", filter: false, visible: true },
  {
    index: "rfc_receptor",
    label: "Rfc receptor",
    filter: false,
    visible: true,
  },
  { index: "uuid", label: "UUID", filter: false, visible: true },
  { index: "status", label: "Estado", filter: false, visible: true },

  {
    index: "response_message",
    label: "Mensaje de respuesta",
    filter: false,
    visible: true,
  },
  {
    index: "fecha_envio",
    label: "Fecha de envio",
    filter: false,
    visible: true,
  },
  {
    index: "sync_message",
    label: "Sync autofac",
    filter: false,
    visible: true,
  },
];

const pagination = {
  columnName: "correo_receptor",
  sortOrder: "ASC",
  rfc: "",
  page: 1, //currentPage
  listaPageSize: [10, 20, 10, 50, 100, 200], //lista de opciones por tamaño // La paginacion en D.component esta por defecto en el lugar 2 osea, 10 registros
  pageSize: 10, //Tamaño de pagina actual
  actualShow: 0, //rango de elementos que se muestran actualmente.
  collectionSize: 0,
};
import { mailService } from "../../helpers/mailService/mail.service";
import TableMailComponent from "@/components/EmailSearchForm/TableMailComponent.vue";
import { helpers, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Loader from "../widgets/loader";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    TableMailComponent,
    Loader,
  },
  data() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();

    const initialDate = new Date(year, month - 1, day, 0, 0, 0, 0);

    const endDate = new Date(year, month - 1, day, 12, 59, 59, 999);

    return {
      loader: false,
      submitted: false,
      headers,
      pagination,
      selectedField: "correo_receptor", // Campo seleccionado en el select
      searchValue: "", // Valor del campo de búsqueda
      correoReceptorValue: "",
      rfcEmisorValue: "",
      rfcReceptorValue: "",
      uuidValue: "",
      initialDate: initialDate.toISOString().substring(0, 10), //  Primera fecha
      endDate: endDate.toISOString().substring(0, 10), // Segunda fecha
      logData: {
        type: Object,
      },
    };
  },
  validations: {
    correoReceptorValue: {
      required: helpers.withMessage("Introduce un correo valido", email),
    },
    rfcEmisorValue: {
      required: helpers.withMessage(
        "Introduce un rfc valido",
        (rfcEmisorValue) => {
          return (
            !rfcEmisorValue ||
            /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
              rfcEmisorValue
            )
          );
        }
      ),
    },
    rfcReceptorValue: {
      required: helpers.withMessage(
        "Introduce un rfc valido",
        (rfcReceptorValue) => {
          return (
            !rfcReceptorValue ||
            /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
              rfcReceptorValue
            )
          );
        }
      ),
    },
    uuidValue: {
      required: helpers.withMessage("Introduce un uuid valido", (uuidValue) => {
        return (
          !uuidValue ||
          /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
            uuidValue
          )
        );
      }),
    },
  },

  methods: {
    consultLog() {
      this.submitted = true; //Inicia el load se puede meter un validador
      this.v$.$touch();
      if (this.v$.$invalid) {
        console.log("Hay errores de validación");
        return;
      }
      if (!this.initialDate || !this.endDate) {
        console.log("Por favor, ingrese fechas validas.");
        return;
      }
      console.log(this.initialDate);
      console.log(this.endDate);
      let value = "";
      if (
        this.selectedField == "correo_receptor" &&
        this.correoReceptorValue != ""
      ) {
        value = this.correoReceptorValue;
      }
      if (this.selectedField == "rfc_emisor" && this.rfcEmisorValue != "") {
        value = this.rfcEmisorValue;
      }
      if (this.selectedField == "rfc_receptor" && this.rfcReceptorValue != "") {
        value = this.rfcReceptorValue;
      }
      if (this.selectedField == "uuid" && this.uuidValue != "") {
        value = this.uuidValue;
      }

      let params = {
        name_field: this.selectedField,
        value: value,
        page: this.pagination.page,
        page_size: 10,
        actualShow: 0,
        collectionSize: 0,
        initial_date: `${this.initialDate} 00:00:00`,
        end_date: `${this.endDate} 23:59:59`,
      };
      console.log(params);
      this.loader = true;
      mailService
        .getLog(params)
        .then((response) => {
          if (response.status == "success") {
            console.log(response.data);
            //acomodar datos a mostrar for each recorrer y sacar datos requeridos.
            let dataToSend = [];
            response.data.forEach((item) => {
              dataToSend.push({
                correo_receptor: item.correo_receptor,
                rfc_emisor: item.rfc_emisor,
                rfc_receptor: item.rfc_receptor,
                uuid: item.uuid,
                status: item.status,
                response_message: item.response_message,
                fecha_envio: item.fecha_envio,
                sync_message: item.sync_message,
              });
            });
            dataToSend = dataToSend.slice(0, 10);
            console.log(dataToSend);
            this.logData = dataToSend;
            this.pagination.actualShow = 10;

            this.pagination.collectionSize = response.data[0]["total"];
          } else {
            console.log("no hay resultados");
          }
          this.loader = false;
        })
        .catch((error) => {
          console.log("Error al obtener datos: ", error);
          this.loader = false;
        });
    },
    getEntidadesFake(pagination) {
      console.log("Desde entidadesFake", pagination.page);
      console.log(this.reponse);

      const pageToConsult = pagination.page;
      let startIndex = 0;
      if (pageToConsult == 1) {
        startIndex = 0;
      } else {
        startIndex = (pageToConsult - 1) * 10 + 1;
      }

      let endIndex = startIndex + 10;
      let dataToSend = this.reponse.slice(startIndex - 1, endIndex - 1);
      console.log(dataToSend);

      // logData se manda por medio de props
      this.logData = dataToSend;
      this.pagination.actualShow = endIndex - 1;
      this.pagination.collectionSize = this.reponse.length;
    },
    //maneja el envío del formulario de búsqueda cuando el usuario hace clic en el botón "Buscar".
  },
  computed: {
    // Propiedades computadas para validar los campos del formulario antes de enviar la solicitud
    selectedFieldFormatted() {
      // Formatea el texto del label "Campo de búsqueda" a partir del valor seleccionado en el select
      // Objeto para formatear el texto de label "correo_receptor" a ""
      const BUSQUEDA_CORREO_RECEPTOR = {
        correo_receptor: "correo receptor",
        rfc_emisor: "rfc emisor",
        rfc_receptor: "rfc receptor",
        uuid: "uuid",
      };
      const valorBusqueda = this.selectedField; // Valor seleccionado en el select
      const formattedField = BUSQUEDA_CORREO_RECEPTOR[valorBusqueda]; // Texto formateado
      return formattedField; // Reemplaza los guiones bajos por espacios en blanco
    },
  },
};
</script>

